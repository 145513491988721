import React from "react";
import "./InfoCard.css";

const InfoCard = ({icn, title, subtitle, description, inverted}) => (
  <div className={`card ${inverted===1 ? 'inverted right' : 'left'}`}>
    <div className="card-icon image" style={{backgroundImage: "url(" + icn + ")"}}></div>
    <div className="card-vertical-separator"></div>
    <div className="card-content">
      <h1 className="card-title">{title}</h1>
      <h3 className="card-subtitle">{subtitle}</h3>
      <div className="card-horizontal-separator"></div>
      <h5 className="card-description">{description}</h5>
    </div>
  </div>
);

export default InfoCard;
