export const projectsData = {
    Monteverde: [
      {
        id: "Lazareth4678",
        name: "Lazareth 4678",
        description:
          "Near Beirut, Lazareth 4678 offers a special green escape in the luxurious Beit Mery Monteverde area.",
        location: "Monteverde",
        elevation: "500m",
        floorCount: "4",
        numApartments: "8",
        numAvailableApartments: "8",
        parkingAvailability: "Yes",
        parkingType: "Underground/GF",
        image: "https://i.imgur.com/teoeiYU.jpeg",
      }/*,
      {
        id: 2,
        name: "Luxury Villa2",
        description:
          "A beautiful villa with stunning views and modern amenities.",
        location: "Monteverde",
        elevation: "500m",
        floorCount: "3",
        numApartments: "10",
        numAvailableApartments: "2",
        parkingAvailability: "Yes",
        parkingType: "Underground",
        image:
          "https://th.bing.com/th/id/OIP.O9nIGE4tMlRXgNs7GmFFLgHaE8?rs=1&pid=ImgDetMain",
      },*/
    ]/*,
    location2: [
      {
        id: 3,
        name: "Project 3",
        description: "Description of Project 3",
        location: "location2",
        elevation: "400m",
        floorCount: "2",
        numApartments: "8",
        numAvailableApartments: "3",
        parkingAvailability: "Yes",
        parkingType: "Overground",
        image: "https://example.com/project3.jpg",
      },
      {
        id: 4,
        name: "Project 4",
        description: "Description of Project 4",
        location: "location2",
        elevation: "400m",
        floorCount: "2",
        numApartments: "8",
        numAvailableApartments: "3",
        parkingAvailability: "Yes",
        parkingType: "Overground",
        image: "https://example.com/project4.jpg",
      },
    ],
    location3: [
      {
        id: 5,
        name: "Project 5",
        description: "Description of Project 5",
        location: "location3",
        elevation: "300m",
        floorCount: "4",
        numApartments: "12",
        numAvailableApartments: "4",
        parkingAvailability: "No",
        parkingType: "None",
        image: "https://example.com/project5.jpg",
      },
      {
        id: 6,
        name: "Project 6",
        description: "Description of Project 6",
        location: "location3",
        elevation: "300m",
        floorCount: "4",
        numApartments: "12",
        numAvailableApartments: "4",
        parkingAvailability: "No",
        parkingType: "None",
        image: "https://example.com/project6.jpg",
      },
    ],*/
    // Add more locations and projects as needed
  };