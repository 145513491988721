import React from 'react';
import { Card, CardContent, CardMedia, Typography, Button, Box } from '@mui/material';
import styles from './ProjectCard.module.css';
import { useNavigate, useParams } from 'react-router-dom';

const ProjectCard = ({ project }) => {
  const navigate = useNavigate();
  const { location } = useParams();

  const handleLearnMore = () => {
    navigate(`/location/${location}/${project.id}`);
  };

  return (
    <Card className={styles.customcard}>
      <Box className={styles.cardContentContainer}>
        <CardMedia
          component="img"
          alt={project.name}
          height="100%"
          image={project.image}
          className={styles.cardMedia}
        />
        <CardContent className={styles.cardContent}>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            className={styles.title}
          >
            {project.name}
          </Typography>
          <Typography variant="body2" color="text.secondary" className={styles.description}>
            {project.description || 'No description available'}
          </Typography>
          <Typography variant="body2" color="text.secondary" className={styles.detail}>
            <strong>Location:</strong> {project.location}
          </Typography>
          <Typography variant="body2" color="text.secondary" className={styles.detail}>
            <strong>Elevation:</strong> {project.elevation}
          </Typography>
          <Typography variant="body2" color="text.secondary" className={styles.detail}>
            <strong>Floor Count:</strong> {project.floorCount}
          </Typography>
          <Typography variant="body2" color="text.secondary" className={styles.detail}>
            <strong>Number of Apartments:</strong> {project.numApartments}
          </Typography>
          <Typography variant="body2" color="text.secondary" className={styles.detail}>
            <strong>Available Apartments:</strong> {project.numAvailableApartments}
          </Typography>
          <Typography variant="body2" color="text.secondary" className={styles.detail}>
            <strong>Parking Availability:</strong> {project.parkingAvailability}
          </Typography>
          <Typography variant="body2" color="text.secondary" className={styles.detail}>
            <strong>Parking Type:</strong> {project.parkingType}
          </Typography>
          <Box className={styles.footer}>
            <Button
              variant="contained"
              className={styles.learnMoreButton}
              onClick={handleLearnMore}
            >
              See More
            </Button>
          </Box>
        </CardContent>
      </Box>
    </Card>
  );
};

export default ProjectCard;
