//import PropertyList from "./components/lists/PropertyList";
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import './App.css';
import Welcome from "./components/pages/Welcome";
import Projects from "./components/pages/Projects"
import Apartments from "./components/pages/Apartments";
import ApartmentDetails from "./components/pages/ApartmentDetails";
import AboutUs from "./components/pages/About";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Welcome />} />
        <Route path="/location/:location" element={<Projects />} />
        <Route path="/location/:location/:projectId" element={<Apartments />} />
        <Route path="/location/:location/:projectId/:apartmentId" element={<ApartmentDetails />} />
        <Route path="/about-us" element={<AboutUs />} />
      </Routes>
    </Router>
  );
}

export default App;
