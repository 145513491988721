import React, { useEffect } from "react";
import "./Header.css";
import { Link, useLocation } from "react-router-dom";
import { projectsData } from "../../assets/comondata/ProjectsData";
import Logo from "../../assets/images/LogoLetters.png"

const Header = () => {
  const location = useLocation();
  const projLocations = Object.keys(projectsData);

  document.body.style.overflow = 'auto';

  useEffect(() => {
    const nav = document.getElementById("navbar");
    const close = document.getElementById("close-btn");
    const burger = document.getElementById("burger-bar-div");
    const background = document.getElementById("horizontal-background");

    const hideFunction = () => {
      nav.classList.add("hidden");
      background.classList.add("hidden-background");
      document.body.style.overflow = 'auto';
    };

    const showFunction = () => {
      nav.classList.remove("hidden");
      background.classList.remove("hidden-background");
      document.body.style.overflow = 'hidden';
    };

    close.addEventListener("click", hideFunction);
    burger.addEventListener("click", showFunction);

    return () => {
      close.removeEventListener("click", hideFunction);
      burger.removeEventListener("click", showFunction);
    };
  }, []);

  return(
    <div>
      <header id="horizontal-header">
        <nav className={`navbar ${location.pathname === "/" ? "transparentbg" : ""}`} >
        <img className="nav-img" src={Logo} alt="Logo"></img>
          <ul className="nav-links">
            <li className={location.pathname === "/" ? "active" : ""}>
              <Link to="/">Home</Link>
            </li>
            <li className={location.pathname.includes('/location') ? "active" : ""}>
              <div className={"dropdown"}>
                <button className="dropbtn">Locations <span className="dropbtn-arrow">▼</span></button>
                <div className="dropdown-content">
                {projLocations.map(location =>
                  <Link key={location} to={`/location/${location}`}>{location}</Link>
                )}  
                </div>
              </div>
            </li>
            <li className={location.pathname === "/about-us" ? "active" : ""}>
              <Link to="/about-us">About Us</Link>
            </li>
          </ul>
        </nav>
      </header>

      <header id="vertical-header">
      <div className="burger-div" id="burger-div">
        <div className="burger-bar-div" id="burger-bar-div">
          <div className="burger-bar"></div>
          <div className="burger-bar"></div>
          <div className="burger-bar"></div>
        </div>
      </div>

      <div className="horizontal-background hidden-background" id="horizontal-background"></div>

      <nav className="navbar hidden" id="navbar">
        <div className="navbar-header">
          <p className="close-btn" id="close-btn">X</p>
          <img className="nav-img" src={Logo} alt="Logo"></img>
        </div>
        <div className="horizontal-separator"></div>
        <ul className="nav-links">
          <li className={location.pathname === "/" ? "active" : ""}><a href="/">Home</a></li>
          <li className={location.pathname.includes('/location') ? "active" : ""}>
            <div className="dropdown">
            <button className="dropbtn">Locations <span className="dropbtn-arrow">▼</span></button>
              <div className="dropdown-content">
                {projLocations.map(location =>
                  <Link key={location} to={`/location/${location}`}>{location}</Link>
                )}  
              </div>
            </div>
          </li>
          <li className={location.pathname === "/about-us" ? "active" : ""}>
            <Link to="/about-us">About Us</Link>
          </li>
        </ul>
      </nav>
      </header>
    </div>
)};

export default Header;