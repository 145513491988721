import React from 'react';
import './About.css';
import Header from "../navigation/Header";
import Footer from '../navigation/Footer';
import Logo from "../../assets/images/Logo Grey.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb } from '@fortawesome/free-regular-svg-icons';

const About = () => {
  return (
    <div className="div-body">
      <Header />
      <main className="about-us">
        <div className="about-us-content">
          <img src={Logo} alt="Logo" />
          <div className="about-us-vertical-separator"></div>
          <div className='about-us-main-content'>
            <h2 className='about-us-main-content-title'>About Us</h2>
            <p>
            At ME Realty, our mission is to empower individuals and families to achieve their real estate dreams through exceptional, personalized service and expert guidance.<br />
            With over two decades of experience, we are dedicated to fostering lasting relationships founded on trust and integrity.<br />
            Our commitment to innovative solutions enhances the buying, selling, and investing experience, making it streamlined and enjoyable.<br /> 
            Let us help you realize your dream home with a process designed for your success.<br />
            </p>
          </div>
        </div>
        <h2 className='about-us-content-title'>Our Mission</h2>
            <div className='missions'>
              <div className='mission-item'><p className='mission-title'>Quality <span class="gold-star">★★★★★</span></p> Delivering top-notch construction and design.</div>
              <div className='mission-item'><p className='mission-title'>Trust <span className='tick'>&#10004;</span></p> Building long-lasting relationships with our clients based on transparency and integrity.</div>
              <div className='mission-item'><p className='mission-title'>Innovation <FontAwesomeIcon icon={faLightbulb} className='light-bulb'/></p> Embracing the latest technologies and trends to offer cutting-edge real estate solutions.</div>
            </div>
      </main>
      <div className="about-us-footer">
        <Footer />
      </div> 
    </div>
  );
};

export default About;
