export const apartmentsData = {
  Lazareth4678: [
      {
        id: 1,
        name: "First floor west",
        floor: 1,
        isduplex: false,
        position: "West",
        area: "213 sqm",
        bedrooms: 3,
        bathrooms: 4,
        maidroom: 1,
        parkingspots: 2,
        terraceavailable: "Yes (140 sqm)",
        terracearea: "140 sqm",
        watertank: "18000L",
        solarsystem: "Yes",
        available: true,
        price: "$2200/sqm",
        image: "https://i.imgur.com/mmmBVHc.jpeg",
        plan: "https://i.imgur.com/Z6OBNvW.jpeg",
        carouselImages: [
          "https://i.imgur.com/wX0YNKa.jpeg",
          "https://i.imgur.com/qC0icbY.jpeg",
          "https://i.imgur.com/mmmBVHc.jpeg",
          "https://i.imgur.com/z1nHele.jpeg",
          "https://i.imgur.com/Gd0UzsR.jpeg",
          "https://i.imgur.com/LGrSfiL.jpeg",
          "https://i.imgur.com/GZyXOBe.jpeg"
        ]
      },
      {
        id: 2,
        name: "First floor east",
        floor: 1,
        isduplex: false,
        position: "East",
        area: "217 sqm",
        bedrooms: 3,
        bathrooms: 4,
        maidroom: 1,
        parkingspots: 2,
        terraceavailable: "Yes (19 sqm)",
        terracearea: "19 sqm",
        watertank: "18000L",
        solarsystem: "Yes",
        available: true,
        price: "$2200/sqm",
        image: "https://i.imgur.com/ZfDnArC.jpeg",
        plan: "https://i.imgur.com/IaoVwZt.jpeg",
        carouselImages: [
          "https://i.imgur.com/2yU15lu.jpeg",
          "https://i.imgur.com/qC0icbY.jpeg",
          "https://i.imgur.com/8UnRjdD.jpeg",
          "https://i.imgur.com/ZfDnArC.jpeg",
          "https://i.imgur.com/z1nHele.jpeg",
          "https://i.imgur.com/rkF0NrU.jpeg",
          "https://i.imgur.com/ZfDnArC.jpeg"
        ]
      },
      {
        id: 3,
        name: "Second floor west",
        floor: 2,
        isduplex: false,
        position: "West",
        area: "213 sqm",
        bedrooms: 3,
        bathrooms: 4,
        maidroom: 1,
        parkingspots: 2,
        terraceavailable: "Yes (40 sqm)",
        terracearea: "40 sqm",
        watertank: "18000L",
        solarsystem: "Yes",
        available: true,
        price: "$2200/sqm",
        image: "https://i.imgur.com/z1nHele.jpeg",
        plan: "https://i.imgur.com/8XjwYTc.png",
        carouselImages: [
          "https://i.imgur.com/wX0YNKa.jpeg",
          "https://i.imgur.com/qC0icbY.jpeg",
          "https://i.imgur.com/z1nHele.jpeg",
          "https://i.imgur.com/gdVS8DY.jpeg",
          "https://i.imgur.com/zBoUs1q.jpeg",
          "https://i.imgur.com/ZgfrU5U.jpeg"
        ]
      },
      {
        id: 4,
        name: "Second floor east",
        floor: 2,
        isduplex: false,
        position: "East",
        area: "217 sqm",
        bedrooms: 3,
        bathrooms: 4,
        maidroom: 1,
        parkingspots: 2,
        terraceavailable: "Yes (47 sqm)",
        terracearea: "47 sqm",
        watertank: "18000L",
        solarsystem: "Yes",
        available: true,
        price: "$2200/sqm",
        image: "https://i.imgur.com/zBoUs1q.jpeg",
        plan: "https://i.imgur.com/G8afbMB.png",
        carouselImages: [
          "https://i.imgur.com/zBoUs1q.jpeg",
          "https://i.imgur.com/wX0YNKa.jpeg",
          "https://i.imgur.com/qC0icbY.jpeg",
          "https://i.imgur.com/z1nHele.jpeg",
          "https://i.imgur.com/Gd0UzsR.jpeg",
          "https://i.imgur.com/LGrSfiL.jpeg",
          "https://i.imgur.com/GZyXOBe.jpeg"
        ]
      },
      {
        id: 5,
        name: "Third floor west",
        floor: 3,
        isduplex: false,
        position: "West",
        area: "213 sqm",
        bedrooms: 3,
        bathrooms: 4,
        maidroom: 1,
        parkingspots: 2,
        terraceavailable: "No",
        terracearea: 0,
        watertank: "18000L",
        solarsystem: "Yes",
        available: true,
        price: "$2200/sqm",
        image: "https://i.imgur.com/LGrSfiL.jpeg",
        plan: "https://i.imgur.com/7XZGLow.png",
        carouselImages: [
          "https://i.imgur.com/LGrSfiL.jpeg",
          "https://i.imgur.com/2yU15lu.jpeg",
          "https://i.imgur.com/qC0icbY.jpeg",
          "https://i.imgur.com/8UnRjdD.jpeg",
          "https://i.imgur.com/z1nHele.jpeg",
          "https://i.imgur.com/rkF0NrU.jpeg",
          "https://i.imgur.com/ZfDnArC.jpeg"
        ]
      },
      {
        id: 6,
        name: "Third floor east",
        floor: 3,
        isduplex: false,
        position: "East",
        area: "217 sqm",
        bedrooms: 3,
        bathrooms: 4,
        maidroom: 1,
        parkingspots: 2,
        terraceavailable: "No",
        terracearea: 0,
        watertank: "18000L",
        solarsystem: "Yes",
        available: true,
        price: "$2200/sqm",
        image: "https://i.imgur.com/rkF0NrU.jpeg",
        plan: "https://i.imgur.com/Ua74VLn.png",
        carouselImages: [
          "https://i.imgur.com/rkF0NrU.jpeg",
          "https://i.imgur.com/wX0YNKa.jpeg",
          "https://i.imgur.com/qC0icbY.jpeg",
          "https://i.imgur.com/gdVS8DY.jpeg",
          "https://i.imgur.com/zBoUs1q.jpeg",
          "https://i.imgur.com/ZgfrU5U.jpeg"
        ]
      },
      {
        id: 7,
        name: "Fourth floor west (Duplex)",
        floor: 4,
        isduplex: true,
        position: "West",
        area: "213 sqm - 55 sqm",
        bedrooms: 3,
        bathrooms: 5,
        maidroom: 1,
        parkingspots: 2,
        terraceavailable: "Yes (46 sqm 2nd floor)",
        terracearea: "46 sqm 2nd floor",
        watertank: "18000L",
        solarsystem: "Yes",
        available: true,
        price: "$2200/sqm",
        image: "https://i.imgur.com/GZyXOBe.jpeg",
        plan: "https://i.imgur.com/yVIbSod.png",
        secondfloorplan: "https://i.imgur.com/gByCh79.png",
        carouselImages: [
          "https://i.imgur.com/GZyXOBe.jpeg",
          "https://i.imgur.com/wX0YNKa.jpeg",
          "https://i.imgur.com/qC0icbY.jpeg",
          "https://i.imgur.com/z1nHele.jpeg",
          "https://i.imgur.com/Gd0UzsR.jpeg",
          "https://i.imgur.com/LGrSfiL.jpeg",
          "https://i.imgur.com/GZyXOBe.jpeg",
          "https://i.imgur.com/yVIbSod.png",
          "https://i.imgur.com/gByCh79.png"
        ]
      },
      {
        id: 8,
        name: "Fourth floor east (Duplex)",
        floor: 4,
        isduplex: true,
        position: "East",
        area: "217 sqm - 65 sqm",
        bedrooms: 4,
        bathrooms: 6,
        maidroom: 1,
        parkingspots: 2,
        terraceavailable: "Yes (40 sqm 2nd floor)",
        terracearea: "40 sqm 2nd floor",
        watertank: "18000L",
        solarsystem: "Yes",
        available: false,
        price: "$2200/sqm",
        image: "https://i.imgur.com/gdVS8DY.jpeg",
        plan: "https://i.imgur.com/Xu9pqmq.png",
        secondfloorplan: "https://i.imgur.com/ELR7MU3.png",
        carouselImages: [
          "https://i.imgur.com/gdVS8DY.jpeg",
          "https://i.imgur.com/2yU15lu.jpeg",
          "https://i.imgur.com/qC0icbY.jpeg",
          "https://i.imgur.com/8UnRjdD.jpeg",
          "https://i.imgur.com/z1nHele.jpeg",
          "https://i.imgur.com/rkF0NrU.jpeg",
          "https://i.imgur.com/ZfDnArC.jpeg",
          "https://i.imgur.com/Xu9pqmq.png",
          "https://i.imgur.com/ELR7MU3.png"
        ]
      },
    ]
  };