import React, { useState, useEffect, useCallback } from 'react';
import './WelcomeCarousel.css';

const Carousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToNext = useCallback(() => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  }, [images.length]);


  const goToIndex = (index) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    const interval = setInterval(goToNext, 10000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [goToNext]);


  return (
    <div className="welcome-carousel">
      <div className="welcome-carousel-inner">
        {images.map((image, index) => (
          <div
            className={`welcome-carousel-item ${index === currentIndex ? 'active' : ''}`}
            key={index}
          >
            <img src={image} alt={`Slide ${index}`} />
          </div>
        ))}
      </div>
      <button className="welcome-carousel-control prev" onClick={goToPrevious}>
        &#10094;
      </button>
      <button className="welcome-carousel-control next" onClick={goToNext}>
        &#10095;
      </button>
      <div className="welcome-carousel-indicators">
        {images.map((_, index) => (
          <span
            key={index}
            className={`welcome-carousel-indicator ${index === currentIndex ? 'active' : ''}`}
            onClick={() => goToIndex(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
