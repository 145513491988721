import React, { useState, useEffect } from "react";
import "./Loading.css";

const Loading =() =>{
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        const handleWindowLoad = () => {
            setLoading(false); // Hide loading when the window has fully loaded
            console.log("Window loaded");
            clearInterval(interval);
            document.body.style.overflow = 'auto';
          };

          var count = 0;
      
          const interval = setInterval(() => {
            // Check if the window is loaded
            if (document.readyState === 'complete') {
              handleWindowLoad();
            }
            count += 1;
            if (count === 20) {
                setLoading(false);
                console.log("The loading is taking to much time!");
                clearInterval(interval);
                document.body.style.overflow = 'auto';
            }

          }, 3000); // Check every 3 seconds
      
          // Event listener for window load
          window.addEventListener("load", handleWindowLoad);
      
          return () => {
            clearInterval(interval); // Clear the interval on cleanup
            window.removeEventListener("load", handleWindowLoad);
          };
    }, []);

    return(
        <div className={`loading-background ${loading ? "" : "loading-done"}`}>
            <div className="spinner">
                <div className="rotatorbox">
                    <div className="dot"></div>
                </div>
                <div className="rotatorbox">
                    <div className="dot"></div>
                </div>
                <div className="rotatorbox">
                    <div className="dot"></div>
                </div>
                <div className="rotatorbox">
                    <div className="dot"></div>
                </div>
                <div className="rotatorbox">
                    <div className="dot"></div>
                </div>
                <div className="rotatorbackground"></div>
            </div>
            <p className="loading-text">Getting things ready for you...</p>
        </div>
    )
}

export default Loading;