import React from "react";
import "./ApartmentDetailsList.css";

const ApartmentDetailsList = ({ dataName1, data1, dataName2, data2 }) => {
  return (
    <div className="my-data-container">
      <div className="col1">
        <p className="col-name">{dataName1}</p>
        <p className="col-data">{data1}</p>
      </div>
      <div className="col-separator"></div>
      <div className="col2">
        <p className="col-name">{dataName2}</p>
        <p className="col-data">{data2}</p>
      </div>
    </div>
  );
};

export default ApartmentDetailsList;