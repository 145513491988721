import React from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const Footer = () => (
    <footer>
        <div className="footer-div">
            <a href="tel:+96170155631" className="button mail"><FontAwesomeIcon icon={faPhone}/></a>
            <a href="https://wa.me/96170155631" className="button whatsapp"><FontAwesomeIcon icon={faWhatsapp}/></a>
            <a href="mailto:me.realtylb@hotmail.com" className="button email"><FontAwesomeIcon icon={faEnvelope}/></a>
        </div>
    </footer>
);

export default Footer;