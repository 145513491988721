import React from "react";
import { Link } from "react-scroll";
import "./Welcome.css";
import Loading from "../others/Loading";
import Header from "../navigation/Header";
import Footer from "../navigation/Footer"
import InfoCard from "../cards/InfoCard";
import WelcomeCarousel from "../cards/WelcomeCarousel"

import Buildings from "../../assets/images/Buildings.jpg"

const WelcomeScreen = () => {
    const welcome_images = [
        "https://i.imgur.com/Sul7RVK.jpeg",
        "https://i.imgur.com/UdMqQIs.jpeg",
        "https://i.imgur.com/CLYoazX.jpeg",
        "https://i.imgur.com/GuXLEKp.jpeg"
      ];

    return (
        <div>
            <Loading />
            <Header />
            <div className="welcome-screen">
                <WelcomeCarousel images={welcome_images} />
                <div className="welcome-div">
                    <div className="welcome-content">
                        <h1>ME Realty Lb</h1>
                        <h3>by Michel Estephan</h3>
                    </div>
                </div>
                <div className="greyFilter"></div>
                <Link 
                        to="cards-title-ref" 
                        smooth={true} 
                        duration={2000} 
                        className="arrows-div"
                    >
                    <div className="arrow-text">Get a Quick Look at Our Project Highlights</div>
                    <div className="arrow1"></div>
                    <div className="arrow2"></div>
                    <div className="arrow3"></div>
                </Link>
            </div>
            <div id="cards-title-ref"></div>
                <div className="cards-title">
                    Get a Quick Look at Our Project Highlights
                </div>
            <div className="cardHandler">
                <InfoCard icn={Buildings}title="1" subtitle="Building" description="Proudly showcasing our completed buildings, each a testament to our commitment to quality and innovation in construction." />
                <InfoCard icn="https://i.imgur.com/wkmwHuu.png"title="1" subtitle="Apartment Sold" description="Trusted by countless homeowners, our sold apartments reflect the confidence and satisfaction of our valued customers." inverted={1} />
                <InfoCard icn="https://th.bing.com/th/id/OIP.6zkvxUugQd8e-2K_lEKGdgHaEU?rs=1&pid=ImgDetMain" title="7" subtitle="Apartments Left" description="Demonstrating our ongoing progress, explore the remaining apartments and become a part of our vibrant community." />
            </div>
            <Footer />
        </div>
    );
};

export default WelcomeScreen;